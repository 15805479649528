import ApiService from "@/core/services/api.service";

export const listModelsMixins = {
  methods: {
    editItem(id) {
      this.$nextTick(() => {
        this.modalData.editedId = id;
        this.modalData.dialog = true;
      });
    },

    handleNew() {
      this.modalData.editedId = null;
      this.modalData.dialog = true;
    },

    // handleSaveModalForm() {
    //   // this.handleCloseModalForm();
    // },
    handleSaveModalForm(item = null) {
      // this.handleCloseModalForm();
      // console.log("item", item);
      if (item) {
        // console.log("this.routePath + item.id", this.routePath + item.id);
        this.handleClickItem(item);
      }
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete && item.id) {
        this.$router.push(this.routePath + item.id);
      }
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.fetchModel();

      if (this.$route.name !== this.routeName)
        this.$router.push({ name: this.routeName });
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    handleDeleteItemConfirm() {
      ApiService.delete(this.endPoint + this.editedItem.id)
        .then(() => {
          this.fetchModel();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.handleCloseDelete();
    },

    handleCloseDelete() {
      this.modalData.editedId = null;
      this.dialogDelete = false;
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == this.permissionTo + action);
    },
  },
};
