<template>
  <div data-app>
    <v-card>
      <v-card-title>
        {{ cardTitle }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="quantityUnitCollection"
        :search="search"
        :sort-by="['name']"
        @click:row="handleClickItem"
        :loading="loadingTable"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <QuantityUnitModalForm
              :modalData="modalData"
              :permissions="permissions"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
              @new="handleNew"
            ></QuantityUnitModalForm>
            <v-spacer></v-spacer>
            <DeleteModalDialog
              :dialogDelete="dialogDelete"
              @closeDelete="handleCloseDelete"
              @deleteItemConfirm="handleDeleteItemConfirm"
            >
            </DeleteModalDialog>
          </v-toolbar>
        </template>
        <!-- <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.companySettings.department[item.status].value
          }}</span>
        </template> -->
        <template v-slot:item.actions="{ item }">
          <!-- <router-link
            :to="`/inventory/quantityunits/${item.id}`"
            v-if="permissionCan('view')"
          >
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </router-link> -->
          <v-icon
            v-if="permissionCan('delete') && item.deletable"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import QuantityUnitModalForm, {
  initialFormData,
} from "./QuantityUnitModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

export const ENDPOINT = "inventory/quantityUnit/";

export const PERMISSION_TO = "inventory.quantityUnit.";

export default {
  name: "quantityUnits",
  components: { QuantityUnitModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      routeName: "quantityUnits",
      routePath: "/inventory/quantityunits/",
      permissionTo: PERMISSION_TO,

      cardTitle: "Quantity units",
      search: "",
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: "Quantity unit",
          value: "name",
        },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      loadingTable: true,
      endPoint: ENDPOINT,
    };
  },

  computed: {
    ...mapGetters(["quantityUnitCollection"]),
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.$nextTick(() => {
            this.editItem(id);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchQuantityUnit"]),
    fetchModel() {
      return this.fetchQuantityUnit();
    },
  },

  mounted() {
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>
